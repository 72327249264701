import React from "react"
import { graphql } from "gatsby"
import { normalizeSponsorBooth } from "../helpers";
import { allRoutes } from "../helpers/routes"
import Img from "gatsby-image"
import Layout from "../components/layout"
import LinkBtn from "../components/linkBtn"
import GoogleMap from "../components/map"
import { SocialLink } from "../assets/images"

export default function SponsorBooth({ data }) {
  const { 
    name, 
    logo, 
    tagline, 
    description,
    descriptionImage,
    address, 
    city,
    state,
    zip,
    addressNotes, 
    phone, 
    email, 
    website
  } = normalizeSponsorBooth(data.allContentfulBoothSponsor.edges[0].node);

  return (
    <Layout>
      <header className="sponsor__header sponsor__header--booth">
        <div className="content-area sponsor__overview">
          {logo && (
            <LinkBtn className="sponsor__logo" to={website}>
              <Img fluid={logo} alt={`${name} logo`} />
            </LinkBtn>
          )}
          <div>
            <h1>{name}</h1>
            {tagline && <p className="sponsor__tagline">{tagline}</p>}
          </div>
        </div>
      </header>
      <div className="content-area">
        <article className="sponsor">
          <div className="sponsor-content">
            {descriptionImage && <Img className="sponsor-content__image" fluid={descriptionImage} alt="" />}
            <div className="rich-text" dangerouslySetInnerHTML={{__html: description }} />
          </div>
          {(address || addressNotes || phone || email || website) &&
            <div className="sponsor-sidebar">
              <h2>Contact Information</h2>
              {address && city && state && zip && (
                <GoogleMap address={`${address} ${city}, ${state} ${zip}`} />
              )}
              {address && (
                <div className="sponsor__address">
                  <span>{address}</span>
                  {city && state && zip && (
                    <>
                      <span className="separator">{" "}•{" "}</span>
                      <span>{city}, {state} {zip}</span>
                    </>
                  )}
                </div>
              )}
              {addressNotes && <p className="sponsor__address-note">{addressNotes}</p>}
              <hr />
              {email && (
                <span className="contact">
                  <LinkBtn className="contact__email link--underline" to={`mailto:${email}`}>
                    {email}
                  </LinkBtn>
                </span>
              )}
              {email && phone && <span className="separator">{" "}•{" "}</span>}
              {phone && (
                <a className="contact contact__phone" href={`tel:${phone}`}>
                  {phone}
                </a>
              )}
              {website && (
                <ul className="social">
                  <li>
                    <LinkBtn to={website} title="Website">
                      <SocialLink />
                      <span className="is-visually-hidden">
                        {website}
                      </span>
                    </LinkBtn>
                  </li>
                </ul>
              )}  
            </div>
          }
        </article>
      </div>
      <div className="cta-block-bottom">
        <LinkBtn className="btn" to={allRoutes.directory}>
          Back to the Directory
        </LinkBtn>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($name: String!) {
    allContentfulBoothSponsor(filter: {vendorName: {eq: $name}}) {
      edges {
        node {
          vendorName
          vendorLogo {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          vendorTagline
          vendorDescription {
            childContentfulRichText {
              html
            }
          }
          vendorDescriptionImage {
            fluid(maxWidth: 200) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          vendorAddress {
            lat
            lon
          }
          vendorStreetAddress
          vendorCity
          state
          vendorAddressZip
          vendorAddressNotes
          vendorPhoneNumber
          vendorEmailAddress
          vendorWebsite
        }
      }
    }
    exhibitHall: file(relativePath: { eq: "exhibit-hall.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`